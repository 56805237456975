import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { COASegment } from '@amzn/allocations-service';
import Modal from '@cloudscape-design/components/modal';
import Box from '@cloudscape-design/components/box';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import FormField from '@cloudscape-design/components/form-field';
import Input from '@cloudscape-design/components/input';
import { useQuery } from '@tanstack/react-query';
import Flashbar from '@cloudscape-design/components/flashbar';
import {
  DimensionActionType,
  DimensionValue,
  initialState,
  reducer,
} from './utils/dimensionsReducer';
import { getCombinations, getDriverMapping } from './utils/driverMapping';
import { Dimensions } from './Dimensions';
import { DriverAttributeEditor } from './DriverAttributeEditor';
import { DriverMapping } from 'src/common/types/DriverMapping';
import { Config } from 'src/common/types/Config';
import QueryKey from 'src/api/QueryKey';
import { AllocationServiceApi } from 'src/api/AllocationServiceApi';
import { getSelectionKeys } from 'src/common/components/tree-select/useTokens';

// Static allocation function for driver mapping
const allocationFunction = 'SplitByRatio';

interface DriverMappingModalProps {
  config: Config;
  driverMapping?: DriverMapping;
  onClose(): void;
  onSubmit(driverMappings: DriverMapping[]): void;
  visible: boolean;
  error?: {
    errorMessage: string;
  };
}

export const DriverMappingModal: FC<DriverMappingModalProps> = (props) => {
  const { t } = useTranslation();
  const [drivers, setDrivers] = useState<string[]>(
    () => props.driverMapping?.drivers ?? [],
  );
  const [dimensions, dispatch] = useReducer(reducer, initialState);

  const defaultValues = useQuery({
    queryKey: [QueryKey.GetDefaultValues],
    queryFn: () => AllocationServiceApi.getDefaultValuesByCOASegment(),
    select: (data) => data.defaultValues ?? {},
  });

  const disabled = useMemo(
    () => !drivers.length || !drivers.every((d) => Boolean(d)),
    [drivers],
  );

  const handleDimensionsChange = useCallback(
    (id: string, value: Partial<DimensionValue>) => {
      dispatch({
        type: DimensionActionType.UPDATE,
        payload: {
          id: id as COASegment,
          value,
        },
      });
    },
    [],
  );

  const handleFilterChange = useCallback(
    (id: string, type: DimensionActionType) => {
      dispatch({ type, payload: { id: id as COASegment, value: {} } });
    },
    [],
  );

  useEffect(() => {
    if (
      Object.values(dimensions).every((dimension) => dimension.defaultValue) ||
      defaultValues.isPending ||
      !defaultValues.data
    ) {
      return;
    }

    Object.entries(defaultValues.data ?? {}).map(([key, value]) => {
      handleDimensionsChange(key, {
        defaultValue: value.dimensionValue?.fieldId,
        hierarchyName: value.hierarchyName,
      });
    });
  }, [defaultValues, dimensions, handleDimensionsChange]);

  const handleSubmit = () => {
    const dimensionValues = Object.values(dimensions).map((dimension) => {
      const { defaultValue, value } = dimension;
      if (!Object.keys(value).length) {
        return defaultValue ? [defaultValue] : [];
      }
      return getSelectionKeys(dimension.value);
    });
    const combinations = getCombinations(...dimensionValues);
    const driverMappings: DriverMapping[] = [];
    combinations.forEach((comb) => {
      const driverMapping: DriverMapping = {};
      Object.keys(dimensions).forEach((d, index) =>
        getDriverMapping(driverMapping, d as COASegment, comb[index]),
      );
      driverMapping.drivers = drivers;
      driverMapping.primaryDriver = driverMapping.drivers?.[0];
      driverMapping.secondaryDriver = driverMapping.drivers?.[1];
      driverMapping.fallbackDriver = driverMapping.drivers?.[2];
      driverMapping.allocationFunctions = [allocationFunction];
      driverMapping.allocationFunction = allocationFunction;
      driverMappings.push(driverMapping);
    });
    props.onSubmit(driverMappings);
  };

  return (
    <Modal
      data-testid="driver-mapping-modal"
      size="large"
      header={t('driver_mapping')}
      visible={props.visible}
      onDismiss={props.onClose}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              data-testid="driver-mapping-modal-cancel"
              onClick={props.onClose}
            >
              {t('cancel')}
            </Button>
            <Button
              data-testid="driver-mapping-modal-submit"
              variant="primary"
              disabled={disabled}
              onClick={handleSubmit}
            >
              {props.driverMapping ? t('update') : t('create')}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="l">
        {props.error && (
          <Flashbar
            items={[
              {
                type: 'error',
                content: props.error.errorMessage,
              },
            ]}
          />
        )}

        {defaultValues.isPending && (
          <Flashbar
            items={[
              {
                type: 'info',
                loading: true,
                content: t('default_hierarchy_values_loading'),
              },
            ]}
          />
        )}
        {defaultValues.isError && (
          <Flashbar
            items={[
              { type: 'error', content: t('default_hierarchy_values_error') },
            ]}
          />
        )}
        <Dimensions
          driverMapping={props.driverMapping}
          dimensions={dimensions}
          onDimensionChange={handleDimensionsChange}
          onFilterChange={handleFilterChange}
        />
        <FormField stretch label={t('allocation_function')}>
          <Input value={allocationFunction} disabled />
        </FormField>
        <DriverAttributeEditor drivers={drivers} onDriverChange={setDrivers} />
      </SpaceBetween>
    </Modal>
  );
};

export default DriverMappingModal;
