import React, { ReactNode, startTransition, useEffect, useState } from 'react';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import Table from '@amzn/awsui-components-react/polaris/table';
import { useTranslation } from 'react-i18next';
import Header from '@amzn/awsui-components-react/polaris/header';
import { TableProps as TableComponentProps } from '@cloudscape-design/components/table';
import SpaceBetween from '@cloudscape-design/components/space-between';
import {
  Button,
  NonCancelableCustomEvent,
  PaginationProps,
  type PropertyFilterProps,
} from '@cloudscape-design/components';
import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';
import PropertyFilter from '@cloudscape-design/components/property-filter';
import {
  DEFAULT_PREFERENCES,
  paginationLabels,
} from 'src/common/components/server-side-table/table-config';
import { getContentDisplayOptions } from 'src/common/utils/table';
import { TableChangeEvent } from 'src/common/types/Events';
import { DriverMappingAction } from 'src/common/components/driver-mapping-components/utils/driverMapping';
import { useNotificationContext } from 'src/common/provider/NotificationProvider';
import { defaultConfig } from 'src/common/types/Config';
import { TablePreferences } from 'src/common/components/TablePreferences';
import DriverMappingModal from 'src/common/components/driver-mapping-components/DriverMappingModal';
import { DriverMapping } from 'src/common/types/DriverMapping';
import { useMetrics } from 'src/common/provider/MetricsProvider';
import { PageAction } from 'src/common/types/PageAction';
import { TableEmptyState } from 'src/common/components/TableEmptyState';
import {
  getEntryWithSameSevenSegmentValue,
  validateUniqueRecordKeyConstraint,
} from 'src/common/utils/driverMapping';
import propertyFilterI18nStrings from 'src/common/constants/propertyFilterI18nStrings';
import FilteringDriverRecordsHandler, {
  FilterType,
} from 'src/shadow-pnl-allocations/pages/configure-slice-allocation-methodology-page/utils/FilteringDriverRecordsHandler';

export interface NextPageResponse {
  driverMappings: DriverMapping[];
  paginationToken: string | undefined;
}

export interface ServerSideTableProps {
  driverMappingId: string;
  columnDefinitions: TableComponentProps.ColumnDefinition<DriverMapping>[];
  header?: string;
  empty?: { title: string; description: string };
  filteringProperties?: PropertyFilterProperty[];
  testId: string;
  handleDownloadCallback: () => Promise<void>;
  fetchNextPage: (
    pageSize: number,
    paginationToken?: string,
    filters?: FilterType,
  ) => Promise<NextPageResponse>;
  updateDriverMappingEntries: (
    driverMappingEntries: Map<DriverMappingAction, DriverMapping[]>,
  ) => Promise<void>;
}

/**
 * This component renders driver mappings for a given driverMappingId and accepts callbacks to fetch and update driver mappings.
 * TODO: Rename this component to DataSourceAgnosticDriverMappingTable
 */
export const ServerSideTable = (props: ServerSideTableProps) => {
  const { t } = useTranslation();
  const { addNotification } = useNotificationContext();
  const queryClient = useQueryClient();
  const metrics = useMetrics();

  const [filters, setFilters] = useState<FilterType>({});
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const [selectedItems, setSelectedItems] = useState<DriverMapping[]>([]);

  const [entriesToUpdateByActionMap, setEntriesToUpdateByActionMap] = useState<
    Map<DriverMappingAction, DriverMapping[]>
  >(new Map());

  const [showDriverMappingModalForAction, setShowDriverMappingModalForAction] =
    useState<DriverMappingAction>();
  const [
    driverMappingModalForActionError,
    setDriverMappingModalForActionError,
  ] = useState<{
    errorMessage: string;
  }>();
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);

  const [downloadDriverMappingInProgress, setDownloadDriverMappingInProgress] =
    useState<boolean>(false);

  /**
   * Resets the state to default values and causes the table to render from first page and refetch the data
   */
  function resetPageState() {
    queryClient
      .invalidateQueries({ queryKey: ['getDriverMapping'] })
      .then(() => {
        setEntriesToUpdateByActionMap(new Map());
        setSelectedItems([]);
        setCurrentPageIndex(1);
      });
  }

  const toggleModalDisplay = (action?: DriverMappingAction) => {
    startTransition(() => setShowDriverMappingModalForAction(action));
  };

  const {
    data: driverMappingPagewiseData,
    fetchNextPage,
    hasNextPage,
    isLoadingError,
    isFetchNextPageError,
    isRefetchError,
    ...fetchDriverMappingPageResponse
  } = useInfiniteQuery<
    NextPageResponse,
    Error,
    InfiniteData<NextPageResponse>,
    (string | number)[],
    string | undefined
  >({
    queryKey: [
      'getDriverMapping',
      props.driverMappingId,
      preferences.pageSize!,
      JSON.stringify(filters),
    ],
    queryFn: ({ pageParam: paginationToken }) =>
      props.fetchNextPage(preferences.pageSize!, paginationToken, filters),
    initialPageParam: undefined, //initial pagination token
    getNextPageParam: (lastPage) => lastPage.paginationToken,
  });

  const {
    mutate: updateDriverMappingEntries,
    isPending: isDriverMappingUpdateInProgress,
  } = useMutation({
    mutationFn: async () => {
      await props.updateDriverMappingEntries(entriesToUpdateByActionMap);
    },
    onSuccess: () => {
      addNotification({
        type: 'success',
        content: t('driver_mapping_update_success'),
      });
      resetPageState();
    },
    onError: () => {
      metrics.publishCounter('DriverMapping.Update', PageAction.Failure, 1);
      addNotification({
        type: 'error',
        content: t('driver_mapping_update_error'),
      });
    },
  });

  useEffect(() => {
    if (isFetchNextPageError || isLoadingError) {
      metrics.publishCounter(
        'DriverMapping.GetDriverMapping',
        PageAction.Failure,
        1,
      );
      addNotification({
        type: 'error',
        content: t('driver_mapping_fetch_error'),
      });
    }
  }, [isFetchNextPageError, isLoadingError]);

  const getDriverMappingEntriesFetchedSoFar = () =>
    driverMappingPagewiseData?.pages?.flatMap((page) => page.driverMappings) ??
    [];

  const getDriverMappingEntriesForPage = (pageNumber: number) =>
    driverMappingPagewiseData?.pages[pageNumber - 1]?.driverMappings;

  const isFirstPage = () => currentPageIndex === 1;

  const handlePreviousPage = () => {
    if (!isFirstPage()) {
      setCurrentPageIndex(currentPageIndex - 1);
    }
  };

  const handleNextPage = async () => {
    if (hasNextPage) {
      await fetchNextPage();
      setCurrentPageIndex(currentPageIndex + 1);
    }
  };

  function handlePageJump(
    changeEvent: NonCancelableCustomEvent<PaginationProps.ChangeDetail>,
  ) {
    //This has to be a jump to an already loaded page as the table is "open ended"
    setCurrentPageIndex(changeEvent.detail.currentPageIndex);
  }

  const handleSelectionChange = ({
    detail,
  }: TableChangeEvent<DriverMapping>) => {
    setSelectedItems(detail.selectedItems);
  };

  function appendToEntriesToUpdate(
    itemsToUpdate: Map<DriverMappingAction, DriverMapping[]>,
  ) {
    const mergedEntries = [
      ...itemsToUpdate.values(),
      ...entriesToUpdateByActionMap.values(),
    ].flatMap((item) => item);
    validateUniqueRecordKeyConstraint(mergedEntries); //validate that entries with duplicate COA combination are not added

    setEntriesToUpdateByActionMap((prevMap) => {
      const newMap = new Map(prevMap);
      itemsToUpdate.forEach((value, key) => {
        newMap.set(key, [...(newMap.get(key) ?? []), ...value]);
      });
      return newMap;
    });
  }

  const handleDownload = async () => {
    try {
      setDownloadDriverMappingInProgress(true);
      await props.handleDownloadCallback();
    } catch {
      metrics.publishCounter('DriverMapping.Download', PageAction.Failure, 1);
      addNotification({
        type: 'error',
        content: t('driver_mapping_download_error'),
      });
    } finally {
      setDownloadDriverMappingInProgress(false);
    }
  };

  const handleDeleteDriverMappingEntries = (
    driverMappings: DriverMapping[],
  ) => {
    try {
      appendToEntriesToUpdate(
        new Map([[DriverMappingAction.DELETE, driverMappings]]),
      );
    } catch {
      addNotification({
        type: 'error',
        content: t('driver_mapping_duplicate_entries_error'),
      });
    }
  };

  function handleEditDriverMappingEntries(
    driverMappings: DriverMapping[],
  ): void {
    const entriesToUpdate = new Map<DriverMappingAction, DriverMapping[]>([
      [DriverMappingAction.EDIT, driverMappings],
    ]);

    const existingSevenSegmentEntry: DriverMapping | undefined =
      //Assuming that the update will happen when only one item is selected
      getEntryWithSameSevenSegmentValue(driverMappings, selectedItems[0]);
    if (!existingSevenSegmentEntry) {
      // We will delete the existing entry when the seven segment value is also updated
      entriesToUpdate.set(DriverMappingAction.DELETE, [selectedItems[0]]);
    }

    try {
      appendToEntriesToUpdate(entriesToUpdate);
      toggleModalDisplay();
    } catch {
      setDriverMappingModalForActionError({
        errorMessage: t('driver_mapping_duplicate_entries_error'),
      });
    }
  }

  function handleAddDriverMappingEntries(driverMappings: DriverMapping[]) {
    try {
      appendToEntriesToUpdate(
        new Map([[DriverMappingAction.ADD, driverMappings]]),
      );
      toggleModalDisplay();
    } catch {
      setDriverMappingModalForActionError({
        errorMessage: t('driver_mapping_duplicate_entries_error'),
      });
    }
  }

  const driverMappingActionHandlers = new Map<
    DriverMappingAction,
    (driverMappings: DriverMapping[]) => void
  >([
    [DriverMappingAction.EDIT, handleEditDriverMappingEntries],
    [DriverMappingAction.DELETE, handleDeleteDriverMappingEntries],
    [DriverMappingAction.ADD, handleAddDriverMappingEntries],
  ]);

  function handleCancelDriverMapping(): void {
    setEntriesToUpdateByActionMap(new Map());
  }

  const isTableDataUpdating = () =>
    fetchDriverMappingPageResponse.isFetchingNextPage ||
    fetchDriverMappingPageResponse.isRefetching ||
    fetchDriverMappingPageResponse.isLoading ||
    isDriverMappingUpdateInProgress;

  const entriesToUpdateSizeForAction = (action: DriverMappingAction) =>
    entriesToUpdateByActionMap.get(action)?.length ?? 0;

  const getEntriesToUpdateSize = () =>
    entriesToUpdateSizeForAction(DriverMappingAction.ADD) +
    entriesToUpdateSizeForAction(DriverMappingAction.EDIT) +
    entriesToUpdateSizeForAction(DriverMappingAction.DELETE);

  //Edit button is enabled when just a single item is selected
  const shouldEnableEditDriverMappingEntryButton = () =>
    selectedItems.length === 1 && !isTableDataUpdating();
  const shouldEnableDeleteDriverMappingEntriesButton = () =>
    selectedItems.length > 0 && !isTableDataUpdating();
  const shouldEnableUpdateButton = () =>
    getEntriesToUpdateSize() > 0 && !isTableDataUpdating();

  const shouldShowDriverMappingEntryModel = () =>
    showDriverMappingModalForAction &&
    (DriverMappingAction.ADD === showDriverMappingModalForAction ||
      DriverMappingAction.EDIT === showDriverMappingModalForAction);

  const tokens =
    FilteringDriverRecordsHandler.transformFiltersToTokens(filters);
  const onFilterChange: PropertyFilterProps['onChange'] = ({ detail }) => {
    FilteringDriverRecordsHandler.handleFilterChange(
      detail,
      setFilters,
      setCurrentPageIndex,
    );
  };

  const headerDescription: ReactNode = <>{t('driver_mapping_instruction')}</>;

  return (
    <>
      {
        /**
         * DriverMappingModal will be visible on ADD / EDIT button clicks.
         */
        shouldShowDriverMappingEntryModel() && (
          <DriverMappingModal
            config={defaultConfig}
            driverMapping={
              showDriverMappingModalForAction === DriverMappingAction.EDIT
                ? selectedItems[0]
                : undefined
            }
            onClose={() => toggleModalDisplay()}
            onSubmit={
              driverMappingActionHandlers.get(showDriverMappingModalForAction!)!
            }
            visible={Boolean(showDriverMappingModalForAction)}
            error={driverMappingModalForActionError}
          />
        )
      }

      <Table
        data-testid={props.testId}
        enableKeyboardNavigation={true}
        loading={isTableDataUpdating()}
        selectedItems={selectedItems}
        empty={
          <TableEmptyState
            title={t('empty_mappings')}
            subtitle={t('empty_mappings_description')}
          />
        }
        items={getDriverMappingEntriesForPage(currentPageIndex) ?? []}
        onSelectionChange={handleSelectionChange}
        columnDefinitions={props.columnDefinitions}
        columnDisplay={preferences.contentDisplay}
        selectionType="multi"
        variant="full-page"
        stickyHeader={true}
        resizableColumns={true}
        wrapLines={preferences.wrapLines}
        stripedRows={preferences.stripedRows}
        contentDensity={preferences.contentDensity}
        stickyColumns={preferences.stickyColumns}
        header={
          <Header
            variant="awsui-h1-sticky"
            description={headerDescription}
            counter={
              !hasNextPage
                ? `(${getDriverMappingEntriesFetchedSoFar().length})`
                : `(${getDriverMappingEntriesFetchedSoFar().length}+)`
            }
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  data-testid="download-driver-mapping"
                  onClick={handleDownload}
                  loading={downloadDriverMappingInProgress}
                >
                  {t('download_driver_mapping')}
                </Button>
                <Button
                  disabled={!shouldEnableDeleteDriverMappingEntriesButton()}
                  data-testid="delete-driver-mapping"
                  onClick={() => {
                    driverMappingActionHandlers.get(
                      DriverMappingAction.DELETE,
                    )!(selectedItems);
                  }}
                >
                  {t('delete_driver_mapping')}
                </Button>
                <Button
                  disabled={!shouldEnableEditDriverMappingEntryButton()}
                  data-testid="edit-driver-mapping"
                  onClick={() => toggleModalDisplay(DriverMappingAction.EDIT)}
                >
                  {t('edit_driver_mapping')}
                </Button>
                <Button
                  variant="primary"
                  data-testid="add-driver-mapping"
                  onClick={() => toggleModalDisplay(DriverMappingAction.ADD)}
                  disabled={isTableDataUpdating()}
                >
                  {t('add_driver_mapping')}
                </Button>
              </SpaceBetween>
            }
          >
            {props.header}
          </Header>
        }
        loadingText={t('table_loading')}
        filter={
          props.filteringProperties ? (
            <PropertyFilter
              data-testid="driver-mapping-filters"
              i18nStrings={propertyFilterI18nStrings(t)}
              filteringProperties={props.filteringProperties}
              query={{ tokens, operation: 'and' }}
              expandToViewport={true}
              onChange={onFilterChange}
              hideOperations={true}
            />
          ) : undefined
        }
        pagination={
          <Pagination
            currentPageIndex={currentPageIndex}
            onNextPageClick={handleNextPage}
            onPreviousPageClick={handlePreviousPage}
            onChange={handlePageJump}
            openEnd={hasNextPage}
            pagesCount={Math.ceil(
              getDriverMappingEntriesFetchedSoFar().length /
                preferences.pageSize!,
            )}
            disabled={isTableDataUpdating()}
            ariaLabels={paginationLabels}
          />
        }
        preferences={
          <TablePreferences
            contentDisplayOptions={getContentDisplayOptions(
              props.columnDefinitions,
            )}
            preferences={preferences}
            setPreferences={setPreferences}
          />
        }
        sortingDisabled={true}
        footer={
          <SpaceBetween direction="horizontal" size="xs" alignItems="end">
            <Button
              variant="primary"
              disabled={!shouldEnableUpdateButton()}
              data-testid="update-driver-mapping"
              onClick={() => updateDriverMappingEntries()}
              loading={isDriverMappingUpdateInProgress}
            >
              {t('update_driver_mapping')}({getEntriesToUpdateSize()})
            </Button>
            <Button
              variant="normal"
              disabled={getEntriesToUpdateSize() < 1}
              data-testid="cancel-driver-mapping"
              onClick={handleCancelDriverMapping}
            >
              {t('cancel_driver_mapping')}
            </Button>
          </SpaceBetween>
        }
      />
    </>
  );
};
