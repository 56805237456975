import {
  Container,
  FormField,
  Header,
  Select,
  Table,
  TableProps,
} from '@cloudscape-design/components';
import { useTranslation } from 'react-i18next';
import SpaceBetween from '@cloudscape-design/components/space-between';
import React, { useState, ReactNode, useEffect } from 'react';
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { TaskType } from '@amzn/taskscheduling-service';
import { scenarioOptions } from 'src/common/constants/environment';
import { SelectChangeEvent } from 'src/common/types/Events';
import { SliceGroup } from 'src/shadow-pnl-allocations/pages/configure-slice-order-page/types/SliceGroup';
import QueryKey from 'src/api/QueryKey';
import { AllocationServiceApi } from 'src/api/AllocationServiceApi';
import { TaskSchedulingServiceApi } from 'src/api/TaskSchedulingServiceApi';
import { useMetrics } from 'src/common/provider/MetricsProvider';
import {
  getDriverMappingIdColumnWithEdit,
  getSliceNameColumn,
  getSliceOrderColumnWithEdit,
} from 'src/shadow-pnl-allocations/pages/configure-slice-order-page/types/Columns';
import updateHandler from 'src/shadow-pnl-allocations/pages/configure-slice-order-page/types/UpdateHandler';
import { useNotificationContext } from 'src/common/provider/NotificationProvider';
import { Page } from 'src/common/types/Page';
import { PageAction } from 'src/common/types/PageAction';
import {useParams} from "react-router";

const ConfigureSliceGroupPage = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const metrics = useMetrics();
  const { addNotification } = useNotificationContext();
  const { scenario: scenarioParam } = useParams();

  const defaultScenarioOption = scenarioParam ? scenarioOptions.find((option) =>
      option.label.toUpperCase() === scenarioParam.toUpperCase()
  )! : scenarioOptions[0];

  /**
   * State Variables for slice-order page
   */
  const [scenario, setSelectedScenario] = useState({
    label: defaultScenarioOption.label,
    value: defaultScenarioOption.value,
  });
  const [sliceSequence, setSliceSequence] = useState<string[]>([]);
  const [rowItems, setRowItems] = useState<SliceGroup[]>([]);

  /**
   *  Component Mutations
   */
  const updateTask = useMutation({
    mutationFn: TaskSchedulingServiceApi.updateTask,
    onSuccess: () => {
      queryClient.invalidateQueries().then(() => {
        //Reset page to re-render everything and re-fetch
        setSliceSequence([]);
        setRowItems([]);
      });
    },
  });
  const updateDriverMapping = useMutation({
    mutationFn: AllocationServiceApi.updateSliceAssociation,
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  /**
   *  Component Queries
   */
  const listTaskQuery = useQuery({
    queryKey: [QueryKey.ListTasks, TaskType.FAE_SLICED_ALLOCATIONS_TASK],
    queryFn: () =>
      TaskSchedulingServiceApi.listTask({
        taskTypes: [TaskType.FAE_SLICED_ALLOCATIONS_TASK],
      }),
    select: (data) => data.taskList ?? [],
  });

  const getSliceQueries = useQueries({
    queries: (sliceSequence || []).map((sliceId) => ({
      queryKey: [QueryKey.GetSliceInformation, sliceId],
      queryFn: () => AllocationServiceApi.getSliceInformation({ sliceId }),
    })),
  });

  const getSliceQueryDataValues = getSliceQueries.map((query) => query.data);

  /**
   *  Use Effect to watch over task query and query data values ( Need to execute only if query data changes )
   */
  useEffect(() => {
    if (listTaskQuery.isSuccess && sliceSequence.length === 0) {
      // Can have only 2 values currently for each scenario
      setSliceSequence(
        scenario.value ===
          listTaskQuery?.data[0]?.input?.slicedAllocationTaskInput?.scenario
          ? listTaskQuery?.data[0]?.input?.slicedAllocationTaskInput
              ?.sliceSequence ?? []
          : listTaskQuery?.data[1]?.input?.slicedAllocationTaskInput
              ?.sliceSequence ?? [],
      );
    }
  }, [listTaskQuery]);

  //JSON.Stringify to trigger only when data values actually change
  useEffect(() => {
    // Controls initial behaviour
    if (getSliceQueries.length === 0) {
      return;
    }

    const allCompleted = getSliceQueries.every((query) => query.isSuccess);
    if (allCompleted) {
      const updatedRowItems = getSliceQueries.map((query, index) => ({
        sliceOrder: (index + 1).toString(),
        sliceId: query.data?.sliceId ?? '',
        sliceName: query.data?.sliceName ?? '',
        driverMappingId: query.data?.dmId ?? '',
      }));

      setRowItems(updatedRowItems);
    }
  }, [JSON.stringify(getSliceQueryDataValues)]);

  /**
   * Handle Function for editing columns
   */
  const handleSubmitEdit = async (
    item: SliceGroup,
    column: TableProps.ColumnDefinition<SliceGroup>,
    newValue: any,
  ) => {
    try {
      column.id === 'sliceOrder'
        ? await updateHandler.handleSliceOrderUpdate(
            item,
            newValue,
            sliceSequence,
            scenario,
            updateTask,
            listTaskQuery.data,
          )
        : await updateHandler.handleDriverMappingUpdate(
            item,
            newValue,
            setSliceSequence,
            setRowItems,
            updateDriverMapping,
          );
      addNotification({
        type: 'success',
        content:
          column.id === 'sliceOrder'
            ? t('slice_sequence_update_success')
            : t('slice_driver_mapping_update_success'),
      });
    } catch {
      metrics.publishCounter(
        `${Page.SliceOrder}.${PageAction.Update}.${column.id}`,
        PageAction.Failure,
        1,
      );
      addNotification({
        type: 'error',
        content:
          column.id === 'sliceOrder'
            ? t('slice_order_update_failure')
            : t('slice_driver_mapping_update_failure'),
      });
    }
  };

  const sliceOrderColumn = getSliceOrderColumnWithEdit(
    sliceSequence.length,
    t('slice_order'),
  );
  const sliceNameColumn = getSliceNameColumn(t('slice_name'));
  const driverMappingIdColumnWithEdit = getDriverMappingIdColumnWithEdit(
    t('driver_mapping_id'),
  );

  const headerDescription: ReactNode = (
    <>
      {t('slice_group_primary_guide')}
      <br />
      {t('slice_group_secondary_guide')}
    </>
  );

  function handleScenarioChange(event: SelectChangeEvent) {
    setSelectedScenario({
      label: event.detail.selectedOption.label!,
      value: event.detail.selectedOption.label!,
    });
    setSliceSequence(
      event.detail.selectedOption.label ===
        listTaskQuery?.data?.[0]?.input?.slicedAllocationTaskInput?.scenario
        ? listTaskQuery?.data?.[0]?.input?.slicedAllocationTaskInput
            ?.sliceSequence ?? []
        : listTaskQuery?.data?.[1]?.input?.slicedAllocationTaskInput
            ?.sliceSequence ?? [],
    );
  }

  return (
    <div data-testid="slice-order-configure-page">
      <Container>
        <SpaceBetween size="xxl">
          <FormField label={t('scenario')} stretch>
            <Select
              data-testid="scenario"
              options={scenarioOptions}
              onChange={handleScenarioChange}
              selectedOption={scenario}
              placeholder={t('scenario_placeholder')}
            />
          </FormField>
          <Table
            data-testid="slice-order-table"
            items={rowItems}
            columnDefinitions={[
              sliceOrderColumn,
              sliceNameColumn,
              driverMappingIdColumnWithEdit,
            ]}
            variant="container"
            header={
              <Header description={headerDescription}>
                {t('slice_group_information')}
              </Header>
            }
            submitEdit={(
              item: SliceGroup,
              column: TableProps.ColumnDefinition<SliceGroup>,
              newValue,
            ) => handleSubmitEdit(item, column, newValue)}
          />
        </SpaceBetween>
      </Container>
    </div>
  );
};

export default ConfigureSliceGroupPage;
