import * as KatalMetrics from '@amzn/katal-metrics';
import KatalMetricsDriverConsoleLogJson from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson';
import KatalMetricsDriverArrayCollector from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverArrayCollector';
import {
  KatalMonitoringDriver,
  KatalMonitoringDriverOptions,
} from '@amzn/katal-monitoring-aws-driver';

const metricsConsoleErrorHandler = (err: Error) => console.error(err);

const makeMetricsDriver = (): KatalMetrics.MetricsDriver => {
  if (process.env.NODE_ENV === 'test') {
    const metricsDriver = new KatalMetricsDriverArrayCollector();
    //  Attach to global window object so tests can see it
    (window as any).metricsDriver = metricsDriver;
    return metricsDriver;
  }
  if (process.env.NODE_ENV !== 'production') {
    return new KatalMetricsDriverConsoleLogJson();
  }
  const monitoringConfig: KatalMonitoringDriverOptions = {
    url: 'https://8mpq2kts0h.execute-api.us-west-2.amazonaws.com/prod/v1/monitoring',
  };
  return new KatalMonitoringDriver(monitoringConfig);
};

const makePublisher = (): KatalMetrics.Publisher => {
  const metricsDriver = makeMetricsDriver();

  const initialMetricsContext = new KatalMetrics.Context.Builder()
    .withSite('Allocations')
    .withServiceName('FinanceAllocationsFrontend')
    .build();

  return new KatalMetrics.Publisher(
    metricsDriver,
    metricsConsoleErrorHandler,
    initialMetricsContext,
  );
};

const initialMetricsPublisher = makePublisher();

export default initialMetricsPublisher;
