import { TableProps } from '@cloudscape-design/components';
import { TFunc } from 'src/common/types';
import { DriverMapping } from 'src/common/types/DriverMapping';
import { formatDate } from 'src/common/utils/date';

export function getAllocationFunctions(item: DriverMapping) {
  return item.allocationFunctions
    ? item.allocationFunctions
    : item.allocationFunction;
}

export function getDrivers(item: DriverMapping) {
  if (item.drivers) {
    return item.drivers;
  }

  return [item.primaryDriver, item.secondaryDriver, item.fallbackDriver]
    .map((item) => item)
    .filter((item) => Boolean(item)) as string[];
}

export const columnDefinitions = (
  t: TFunc,
): TableProps.ColumnDefinition<DriverMapping>[] => [
  {
    id: 'account',
    header: t('account'),
    cell: (item: DriverMapping) => item.account,
    sortingField: 'account',
    minWidth: 120,
  },
  {
    id: 'costCenter',
    header: t('cost_center'),
    cell: (item: DriverMapping) => item.costCenter,
    sortingField: 'costCenter',
    minWidth: 150,
  },
  {
    id: 'companyCode',
    header: t('company_code'),
    cell: (item: DriverMapping) => item.companyCode,
    sortingField: 'companyCode',
    minWidth: 150,
  },
  {
    id: 'locationCode',
    header: t('location_code'),
    cell: (item: DriverMapping) => item.locationCode,
    sortingField: 'locationCode',
    minWidth: 150,
  },
  {
    id: 'productCode',
    header: t('product_code'),
    cell: (item: DriverMapping) => item.productCode,
    sortingField: 'productCode',
    minWidth: 150,
  },
  {
    id: 'projectCode',
    header: t('project_code'),
    cell: (item: DriverMapping) => item.projectCode,
    sortingField: 'projectCode',
    minWidth: 150,
  },
  {
    id: 'channelCode',
    header: t('channel_code'),
    cell: (item: DriverMapping) => item.channelCode,
    sortingField: 'channelCode',
    minWidth: 150,
  },
  {
    id: 'allocationFunctions',
    header: t('allocation_function'),
    cell: (item: DriverMapping) => getAllocationFunctions(item),
    sortingField: 'allocationFunctions',
    minWidth: 150,
  },
  {
    id: 'drivers',
    header: t('drivers'),
    cell: (item: DriverMapping) => getDrivers(item).join(', '),
    sortingField: 'drivers',
    minWidth: 180,
  },
  {
    id: 'lastUpdatedTime',
    header: t('last_updated_date'),
    cell: (item: DriverMapping) => formatDate(item.lastUpdatedTime),
    sortingField: 'lastUpdatedTime',
    minWidth: 150,
  },
];
