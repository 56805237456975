import { FilterOperator } from '@amzn/allocations-service';
import React, { Dispatch } from 'react';
import type { PropertyFilterQuery } from '@amzn/awsui-collection-hooks';

export type FilterType = Record<string, { operator: string; operand: string }>;

// Create a mapping from string operators to FilterOperator enum values
export const OPERATOR_TO_FILTER_MAP: Record<string, string> = {
  ':': FilterOperator.CONTAINS,
  '=': FilterOperator.EQUALS,
  '!=': FilterOperator.NOT_EQUALS,
  '!:': FilterOperator.NOT_CONTAINS,
};

// Create a reverse mapping from FilterOperator enum values to string operators
const FILTER_TO_OPERATOR_MAP: Record<string, string> = {
  [FilterOperator.CONTAINS]: ':',
  [FilterOperator.EQUALS]: '=',
  [FilterOperator.NOT_EQUALS]: '!=',
  [FilterOperator.NOT_CONTAINS]: '!:',
};

type SetStateFn<T> = Dispatch<React.SetStateAction<T>>;

/**
 * Class to define filtering related methods for driver records in Configure slice allocation page
 */
class FilteringDriverRecordsHandler {
  /**
   * Function to handle filter change for Driver Mapping Records in Configure Slice Allocation Page
   *
   * @param filterDetail
   * @param setFilters
   * @param setCurrentPageIndex
   */
  handleFilterChange(
    filterDetail: PropertyFilterQuery,
    setFilters: SetStateFn<FilterType>,
    setCurrentPageIndex: SetStateFn<number>,
  ): void {
    const { tokens } = filterDetail;

    if (tokens.length !== 0) {
      // No filters applied
      const apiFilters = tokens.reduce<
        Record<string, { operator: string; operand: string }>
      >((acc, token) => {
        if (token.propertyKey && token.value) {
          const operator = OPERATOR_TO_FILTER_MAP[token.operator];
          acc[token.propertyKey] = {
            operator,
            operand: token.value,
          };
        }
        return acc;
      }, {});

      setFilters(apiFilters);
      setCurrentPageIndex(1);
    } else {
      setFilters({});
    }
  }

  /**
   * Function to transform API Filters to Cloudscape filter Tokens
   *
   * @param filters
   */
  transformFiltersToTokens(filters: FilterType) {
    return Object.entries(filters).map(([key, value]) => ({
      propertyKey: key,
      operator: FILTER_TO_OPERATOR_MAP[value.operator],
      value: value.operand,
    }));
  }
}

export default new FilteringDriverRecordsHandler();
