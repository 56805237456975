import { DriverMapping, DriverMappingRecord } from '@amzn/allocations-service';
import { getDrivers } from 'src/common/components/driver-mapping-components/config/driver-mapping-table';
import { DriverMappingAction } from 'src/common/components/driver-mapping-components/utils/driverMapping';

const COAFields = [
  'account',
  'costCenter',
  'locationCode',
  'productCode',
  'projectCode',
  'companyCode',
  'channelCode',
] as const;

const toDriverMappingRecords = (
  driverMappings: DriverMapping[],
): DriverMappingRecord[] =>
  driverMappings.map((driverMapping) => ({
    account: driverMapping.account,
    costCenter: driverMapping.costCenter,
    productCode: driverMapping.productCode,
    projectCode: driverMapping.projectCode,
    locationCode: driverMapping.locationCode,
    channelCode: driverMapping.channelCode,
    companyCode: driverMapping.companyCode,
    drivers: getDrivers(driverMapping),
    ...driverMapping,
  }));

const toDriverMappingRecordsByActionMap = (
  driverMappingsByActionMap: Map<DriverMappingAction, DriverMapping[]>,
): Map<DriverMappingAction, DriverMappingRecord[]> => {
  const driverMappingRecordsByActionMap = new Map();
  driverMappingsByActionMap.forEach((value, key) =>
    driverMappingRecordsByActionMap.set(key, toDriverMappingRecords(value)),
  );
  return driverMappingRecordsByActionMap;
};

const isSameCOACombination = (
  existingDriverMapping: DriverMapping,
  updatedDriverMapping: DriverMapping,
) =>
  COAFields.every(
    (field) => existingDriverMapping[field] === updatedDriverMapping[field],
  );

const filterWithSameCOACombination = (
  entries: DriverMapping[],
  coaCombinationToFilter: DriverMapping,
) =>
  entries.filter((entry) =>
    isSameCOACombination(entry, coaCombinationToFilter),
  );

const getEntryWithSameSevenSegmentValue = (
  updatedDriverMappings: DriverMapping[],
  existingDriverMapping: DriverMapping,
) =>
  updatedDriverMappings.find((updatedRecord) =>
    isSameCOACombination(existingDriverMapping, updatedRecord),
  );

/**
 * This function throws an error if there are more than one record with the same COA Combination
 * in the given list of driver mapping records. This is to ensure that in any update activity,
 * one record is only updated (i.e. created / deleted / edited) just once.
 * @param mergedEntries
 */
const validateUniqueRecordKeyConstraint = (mergedEntries: DriverMapping[]) => {
  mergedEntries.forEach((entryToValidate) => {
    const entriesWithSameCOACombination = filterWithSameCOACombination(
      mergedEntries,
      entryToValidate,
    );
    if (entriesWithSameCOACombination.length > 1) {
      console.log('Invalid action');
      throw new Error(
        'Can not add / update more than one record with the same COA Combination',
      );
    }
  });
};

export {
  validateUniqueRecordKeyConstraint,
  getEntryWithSameSevenSegmentValue,
  filterWithSameCOACombination,
  isSameCOACombination,
  toDriverMappingRecords,
  toDriverMappingRecordsByActionMap,
};
